import { CountryCodes, Market } from '@bridebook/toolbox/src/gazetteer';
import { UrlHelper } from 'lib/url-helper';

export enum LandingPageIds {
  localeHome = 'localeHome',
  weddingPlanningTools = 'weddingPlanningTools',
  toolsGuestlist = 'toolsGuestlist',
  toolsShortlist = 'toolsShortlist',
  toolsBudget = 'toolsBudget',
  toolsChecklist = 'toolsChecklist',
  weddingGiftLists = 'weddingGiftLists',
  bridesmaidDresses = 'bridesmaidDresses',
  weddingSuppliers = 'weddingSuppliers',
  weddingIdeasInspiration = 'weddingIdeasInspiration',
  termsConditions = 'termsConditions',
  privacyPolicy = 'privacyPolicy',
  cookiePolicy = 'cookiePolicy',
  aboutUs = 'aboutUs',
  contactUs = 'contactUs',
  pressAndMedia = 'pressAndMedia',
  appSupport = 'appSupport',
}

// Webflow folder names are equal to market prefix, the only different one is a universal `global` folder.
export const WEBFLOW_GLOBAL = 'global';

// Helper constant for all folders defined in Webflow, except the Global folder
const allCountryFolders = [CountryCodes.GB, CountryCodes.DE, CountryCodes.FR, CountryCodes.IE];

/**
 * Returns a list of existing Webflow Landing Pages
 */
export const WebflowLandingPages: Record<
  LandingPageIds,
  {
    // Path to the page on Bridebook (without market prefix)
    bridebookPath: string;
    // Path to the page on Webflow (without the first-level country folder)
    webflowPath: string;
    // Specifies for which countries a landing page exists in Webflow.
    // Can be either a specific country folder or a universal global folder.
    // This array is also used to create alternate links for the page.
    countries: (typeof WEBFLOW_GLOBAL | Market['country'])[];
  }
> = {
  [LandingPageIds.localeHome]: {
    bridebookPath: UrlHelper.index,
    webflowPath: '/home',
    countries: [WEBFLOW_GLOBAL, ...allCountryFolders],
  },
  [LandingPageIds.weddingPlanningTools]: {
    bridebookPath: UrlHelper.weddingPlanningTools.main,
    webflowPath: UrlHelper.weddingPlanningTools.main,
    countries: [WEBFLOW_GLOBAL, ...allCountryFolders],
  },
  [LandingPageIds.toolsGuestlist]: {
    bridebookPath: UrlHelper.weddingPlanningTools.guestlist,
    webflowPath: UrlHelper.weddingPlanningTools.guestlist,
    countries: [WEBFLOW_GLOBAL, ...allCountryFolders],
  },
  [LandingPageIds.toolsShortlist]: {
    bridebookPath: UrlHelper.weddingPlanningTools.shortlist,
    webflowPath: UrlHelper.weddingPlanningTools.shortlist,
    countries: [WEBFLOW_GLOBAL, ...allCountryFolders],
  },
  [LandingPageIds.toolsBudget]: {
    bridebookPath: UrlHelper.weddingPlanningTools.budget,
    webflowPath: UrlHelper.weddingPlanningTools.budget,
    countries: [WEBFLOW_GLOBAL, ...allCountryFolders],
  },
  [LandingPageIds.toolsChecklist]: {
    bridebookPath: UrlHelper.weddingPlanningTools.checklist,
    webflowPath: UrlHelper.weddingPlanningTools.checklist,
    countries: [WEBFLOW_GLOBAL, ...allCountryFolders],
  },
  [LandingPageIds.weddingGiftLists]: {
    bridebookPath: UrlHelper.weddingGiftLists,
    webflowPath: UrlHelper.weddingGiftLists,
    countries: [CountryCodes.GB],
  },
  [LandingPageIds.bridesmaidDresses]: {
    bridebookPath: UrlHelper.bridesmaidDresses,
    webflowPath: UrlHelper.bridesmaidDresses,
    countries: [CountryCodes.GB],
  },
  [LandingPageIds.weddingSuppliers]: {
    bridebookPath: UrlHelper.search,
    webflowPath: '/wedding-suppliers',
    countries: [WEBFLOW_GLOBAL, ...allCountryFolders],
  },
  [LandingPageIds.weddingIdeasInspiration]: {
    bridebookPath: UrlHelper.weddingIdeasInspiration,
    webflowPath: UrlHelper.weddingIdeasInspiration,
    countries: [WEBFLOW_GLOBAL, ...allCountryFolders],
  },
  [LandingPageIds.termsConditions]: {
    bridebookPath: UrlHelper.terms,
    webflowPath: UrlHelper.terms,
    countries: [WEBFLOW_GLOBAL, ...allCountryFolders],
  },
  [LandingPageIds.privacyPolicy]: {
    bridebookPath: UrlHelper.privacyPolicy,
    webflowPath: UrlHelper.privacyPolicy,
    countries: [WEBFLOW_GLOBAL, ...allCountryFolders],
  },
  [LandingPageIds.cookiePolicy]: {
    bridebookPath: UrlHelper.cookiePolicy,
    webflowPath: UrlHelper.cookiePolicy,
    countries: [WEBFLOW_GLOBAL, CountryCodes.DE, CountryCodes.FR],
  },
  [LandingPageIds.aboutUs]: {
    bridebookPath: UrlHelper.aboutUs,
    webflowPath: UrlHelper.aboutUs,
    countries: [WEBFLOW_GLOBAL, CountryCodes.DE, CountryCodes.FR],
  },
  [LandingPageIds.contactUs]: {
    bridebookPath: UrlHelper.contactUs,
    webflowPath: UrlHelper.contactUs,
    countries: [WEBFLOW_GLOBAL, ...allCountryFolders],
  },
  [LandingPageIds.pressAndMedia]: {
    bridebookPath: UrlHelper.pressAndMedia,
    webflowPath: UrlHelper.pressAndMedia,
    countries: [WEBFLOW_GLOBAL, ...allCountryFolders],
  },
  [LandingPageIds.appSupport]: {
    bridebookPath: UrlHelper.appSupport,
    webflowPath: UrlHelper.appSupport,
    countries: [WEBFLOW_GLOBAL, CountryCodes.DE, CountryCodes.FR],
  },
};

/**
 * Returns true if a specific LandingPageIds exists for a given market (or globally)
 */
export const landingPageExists = (pageId: LandingPageIds, market: Market) =>
  WebflowLandingPages[pageId].countries.includes(market.country) ||
  WebflowLandingPages[pageId].countries.includes(WEBFLOW_GLOBAL);

export const getIsPathLandingPage = (pathname: string) =>
  [
    UrlHelper.weddingPlanningTools.main,
    UrlHelper.weddingIdeasInspiration,
    UrlHelper.weddingGiftLists,
    UrlHelper.bridesmaidDresses,
    UrlHelper.terms,
    UrlHelper.privacyPolicy,
    UrlHelper.cookiePolicy,
    UrlHelper.aboutUs,
    UrlHelper.contactUs,
    UrlHelper.pressAndMedia,
    UrlHelper.appSupport,
  ].some((landingPagePathname) => pathname.includes(landingPagePathname));
