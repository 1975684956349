import type { ISupplier_PricingIndicator } from '@bridebook/models/source/models/Suppliers.types';
import { PricingCategory } from './types';

export const pricingCategoryByIndicator: Record<ISupplier_PricingIndicator, PricingCategory> = {
  $: PricingCategory.Affordable,
  $$: PricingCategory.Moderate,
  $$$: PricingCategory.Luxury,
  $$$$: PricingCategory.SuperLuxury,
};

export const REVIEWS_PER_PAGE = 6;
export const REVIEWS_PER_PAGE_AB = 10;

export const websiteUrlParams = 'utm_source=bridebook&utm_medium=profile';
